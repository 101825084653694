//import('import/js/flatpickr.js');
//import('import/js/et.js');

var enddate;
var startdate;

namespace gotoAndPlay {
    $(document).ready(function(){

        //https://chmln.github.io/flatpickr
        enddate = flatpickr(".filter__date--end", {
            //dateFormat: '',
            altInput: true,
            altFormat: 'd.m.Y',
            locale: 'et'
        });

        startdate = flatpickr(".filter__date--start", {
            //dateFormat: '',
            altInput: true,
            altFormat: 'd.m.Y',
            locale: 'et',
            onChange: function(selectedDates, dateStr, instance) {
                enddate.set("minDate", dateStr);
            }
        });
    });
}
