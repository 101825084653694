declare let tinymce: any;

namespace gotoAndPlay {

    class Filter {

        private searchInput     = '.js-search-input-text';
        private searchRoleInput = '.js-search-input-role';
        private searchButton    = '.js-search-button';
        private baseUrl         = window.location.href.split('page/')[0].split('/?')[0];
        private xhr;
        private timeout;
        private searchValue;
        private searchRoles;

        constructor() {
            $(document).on('click', this.searchButton, () => {
                this.search(0);
                $('.js-visible').removeClass('js-visible');
                $('.js-search-button').addClass('js-loading');
            });
        }

        public search(timeout: number = 500, force: boolean = false) {
            let searchValue: string = String($(this.searchInput).val());
            let searchRoles: string[] = [];
            $(this.searchRoleInput).each(function() {
                if ($(this).attr('checked') == 'checked') {
                    searchRoles.push($(this).attr('data-category-slug'));
                }
            });

            if (searchValue != this.searchValue || searchRoles != this.searchRoles || force) {
                if (this.xhr) {
                    this.xhr.abort();
                }
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => {
                    this.searchValue = searchValue;
                    this.searchRoles  = searchRoles;
                    this.xhr         = $.post(ajaxPath, {
                        action: 'people-search',
                        data: {
                            input: this.searchValue,
                            roles: this.searchRoles,
                            base_url: this.baseUrl,
                        },
                    });
                    this.xhr.then((result) => {
                        window.history.pushState('object or string', 'Title', result['new_url']);
                        $('.js-people__list').replaceWith($('.js-people__list', result['response_html']));
                        $(document).trigger('reload');
                        $('.js-search-button').removeClass('js-loading');
                    });
                }, timeout);
            }
        }

        public update() {
            this.search(0, true);
        }
    }

    let filter = new Filter();

    /*$(function() {

    });*/

}
