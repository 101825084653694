namespace gotoAndPlay {

    class NewsFilter {

        private searchInput           = '.js-search-input-text';
        private startDateInput        = '.js-filter__date--start';
        private endDateInput          = '.js-filter__date--end';
        private searchCategoriesInput = '.js-search-categories-input';
        private searchButton          = '.js-search-news-button';
        private baseUrl               = window.location.href.split('/page/')[0].split('/?')[0];
        private xhr;
        private timeout;
        private searchValue;
        private startDateValue;
        private endDateValue;
        private searchCategories;

        constructor() {
            $(document).on('click', this.searchButton, () => {
                this.search(0);
                $('.js-visible').removeClass('js-visible');
                $('.filter .button').addClass('js-loading');
            });
        }

        public search(timeout: number = 500, force: boolean = false) {
            console.log(this.baseUrl);
            let searchValue: string        = String($(this.searchInput).val());
            let startDateValue: string     = String($(this.startDateInput).val());
            let endDateValue: string       = String($(this.endDateInput).val());
            let searchCategories: string[] = [];
            $(this.searchCategoriesInput).each(function() {
                if ($(this).attr('checked') == 'checked') {
                    searchCategories.push($(this).attr('data-category-slug'));
                }
            });

            if (searchValue != this.searchValue || startDateValue != this.startDateValue || endDateValue != this.endDateValue || searchCategories != this.searchCategories || force) {
                if (this.xhr) {
                    this.xhr.abort();
                }
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => {
                    this.searchValue      = searchValue;
                    this.startDateValue   = startDateValue;
                    this.endDateValue     = endDateValue;
                    this.searchCategories = searchCategories;
                    this.xhr              = $.post(ajaxPath, {
                        action: 'news-search',
                        data: {
                            input: this.searchValue,
                            start_date: this.startDateValue,
                            end_date: this.endDateValue,
                            categories: this.searchCategories,
                            base_url: this.baseUrl,
                        },
                    });
                    this.xhr.then((result) => {
                        window.history.pushState('object or string', 'Title', result['new_url']);
                        $('.js-news__list').replaceWith($('.js-news__list', result['response_html']));
                        $(document).trigger('reload');
                        $('.filter .button').removeClass('js-loading');
                    });
                }, timeout);
            }
        }

        public update() {
            this.search(0, true);
        }
    }

    let filter = new NewsFilter();

}
