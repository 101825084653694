namespace gotoAndPlay {

    export interface ITextFieldSettings {
        focusClass?: string;
        dirtyClass?: string;
    }

    export class TextField {

        static initSelector: string = '.form-textfield';

        public settings: ITextFieldSettings;
        public element: JQuery;
        public input: JQuery;
        public label: JQuery;

        constructor(target: HTMLElement) {
            this.element  = $(target);
            this.settings = $.extend({
                dirtyClass: 'is-dirty',
                focusClass: 'is-focused',
            }, this.element.data());
            this.input    = this.element.find('.form-textfield__input');
            this.label    = this.element.find('.form-textfield__label');
            this.input.on('focus', this.onFocus.bind(this));
            this.input.on('blur', this.onBlur.bind(this));
            this.input.on('change', this.onChange.bind(this));
            this.checkValue();
        }

        onFocus(event: JQuery.Event): void {
            this.element.addClass(this.settings.focusClass);
        }

        onBlur(event: JQuery.Event): void {
            setTimeout(() => {
                this.element.removeClass(this.settings.focusClass);
            }, 100);
        }

        onChange(event: JQuery.Event): void {
            this.checkValue();
        }

        checkValue(): void {
            if (this.input.val() === '') {
                this.element.removeClass(this.settings.dirtyClass);
            } else {
                this.element.addClass(this.settings.dirtyClass);
            }
        }
    }

    $(document).on('enhance.textfield', function(event) {
        $(event.target).find(TextField.initSelector).addBack(TextField.initSelector).each((index: number, element: HTMLElement) => {
            let textfield = $(element);
            if (!textfield.data('textfieldClass')) {
                textfield.data('textfieldClass', new TextField(element));
            }
        });
    });

    $(() => {
        $(document).trigger('enhance.textfield');
    });

}
