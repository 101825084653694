namespace gotoAndPlay {

    export class Cookie {

        static set(cookieName: string, cookieValue: string, expireHours = 24 * 30): void {
            let d: Date = new Date();
            d.setTime(d.getTime() + ((expireHours ? expireHours : 1) * 60 * 60 * 1000));
            let expires: string = 'expires=' + d.toUTCString();
            document.cookie     = cookieName + '=' + cookieValue + '; ' + expires + '; path=' + cookiePath + ';domain=' + cookieDomain;
        }

        static get(cookieName: string): string {
            let name: string = cookieName + '=';
            let ca: string[] = document.cookie.split(';');
            for (let i: number = 0; i < ca.length; i++) {
                let c: string = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }

            return '';
        }
    }

}
