namespace gotoAndPlay {

    export enum TabsAnimationType {
        Slide,
        Fade,
        FadeAndSlide,
    }

    export enum TabsAnimationDirection {
        In,
        Out,
    }

    export interface ITabsSettings {
        OpenClass?: string;
        CurrentClass?: string;
        TabsClass?: string;
        ControlClass?: string;
        ContentClass?: string;
        AnimationSlideTime?: number;
        AnimationFadeTime?: number;
        AnimationSlideEase?: string;
        AnimationFadeEase?: string;
        AnimationType?: TabsAnimationType;
    }

    export const TabsSettings = {
        AnimationFadeEase: 'swing',
        AnimationFadeTime: 500,
        AnimationSlideEase: 'swing',
        AnimationSlideTime: 500,
        AnimationType: TabsAnimationType.FadeAndSlide,
        ContentClass: 'js-tabs-content',
        ControlClass: 'js-tabs-control',
        CurrentClass: 'is-current',
        OpenClass: 'is-open',
        TabsClass: 'js-tabs',
    };

    export class Tabs {

        settings: ITabsSettings;
        element: JQuery;
        timeout: any;

        private onOpenComplete: () => void;
        private onCloseComplete: () => void;

        constructor(target: HTMLElement, settings: ITabsSettings = {}) {
            this.settings = jQuery.extend(TabsSettings, settings) as ITabsSettings;
            this.element  = $(target);
        }

        getTabs(): JQuery {
            let tabs: JQuery;
            if (this.element.hasClass(this.settings.TabsClass)) {
                tabs = this.element.addClass(this.settings.TabsClass);
            } else {
                tabs = this.element.parents('.' + this.settings.TabsClass);
            }

            return tabs.length ? $(tabs.get(0)) : tabs;
        }

        getCurrentTab(): JQuery {
            let element: JQuery;
            if (this.element.hasClass(this.settings.ControlClass)) {
                element = $(this.element.attr('href'));
            } else if (this.element.hasClass(this.settings.ContentClass)) {
                element = this.element;
            }

            return element;
        }

        open(tab: JQuery = null, recursive: boolean = false, initialLoad: boolean = false): void {
            if (!tab) {
                tab = this.getCurrentTab();
            }
            const parents: JQuery              = this.getTabs();
            let hasActiveControlClass: boolean = false;
            if (parents.length) {
                hasActiveControlClass = parents.find('[href="#' + tab.attr('id') + '"].' + this.settings.ControlClass).hasClass(this.settings.CurrentClass);
            }
            if (tab.length && !tab.hasClass(this.settings.OpenClass) && !hasActiveControlClass) {
                this.onCloseComplete = () => {
                    if (tab.length) {
                        tab.addClass(this.settings.OpenClass);
                        if (!recursive && !Helpers.isOnScreen($(tab.get(0)))) {
                            Helpers.scrollToTarget(tab);
                        }
                    }
                    if (!recursive && !initialLoad) {
                        this.animate(TabsAnimationDirection.In, tab);
                    }
                };
                if (parents.length) {
                    this.closeAll(parents, recursive || initialLoad, tab);
                    const parent: JQuery = parents.parents('.' + this.settings.TabsClass);
                    if (parent.length) {
                        const parentTabs: Tabs = new Tabs(parent.get(0));
                        parentTabs.open(null, true, initialLoad);
                    }
                    if (!recursive && !initialLoad) {
                        // sweet dreams
                    } else {
                        this.onCloseComplete();
                    }
                } else {
                    this.onCloseComplete();
                }
                if (parents.length) {
                    parents.find('[href="#' + tab.attr('id') + '"].' + this.settings.ControlClass).addClass(this.settings.CurrentClass);
                }
            }
        }

        close(tab: JQuery = null, recursive: boolean = false, newTab: JQuery = null): void {
            if (!tab) {
                tab = this.getCurrentTab();
            }
            if (tab.length) {
                if (!recursive) {
                    this.animate(TabsAnimationDirection.Out, tab, () => {
                        tab.removeClass(this.settings.OpenClass);
                    }, newTab);
                } else {
                    tab.removeClass(this.settings.OpenClass);
                }
            }
        }

        closeAll(tabs: JQuery = null, recursive: boolean = false, newTab: JQuery = null): void {
            if (!tabs) {
                tabs = this.getTabs();
            }
            if (tabs.length) {
                const tab: JQuery  = this.getCurrentTab();
                const open: JQuery = tabs.find('.' + this.settings.ContentClass);
                open.each((index: number, element: HTMLElement) => {
                    if (!$(element).is(tab)) {
                        this.close($(element), recursive, newTab);
                    }
                });
                tabs.find('.' + this.settings.ControlClass).removeClass(this.settings.CurrentClass);
            }
        }

        animate(type: TabsAnimationDirection, tab: JQuery = null, onComplete: () => void = null, newtab: JQuery = null): void {
            if (!tab) {
                tab = this.getCurrentTab();
            }
            if (tab.length) {
                const triggerOnComplete: () => void = () => {
                    tab.removeAttr('style');
                    if (onComplete) {
                        onComplete();
                    }
                    switch (type) {
                        case TabsAnimationDirection.In:
                            if (this.onOpenComplete) {
                                this.onOpenComplete();
                            }
                            this.onOpenComplete = null;
                            break;
                        case TabsAnimationDirection.Out:
                            if (this.onCloseComplete) {
                                this.onCloseComplete();
                            }
                            this.onCloseComplete = null;
                            break;
                    }
                };
                if (tab.length) {
                    if (tab.get(0).offsetHeight === 0) {
                        tab.removeAttr('style');
                    }
                    const contentHeight: string  = tab.get(0).offsetHeight + 'px';
                    let newContentHeight: string = '0px';
                    if (newtab) {
                        const isOpen: boolean = newtab.hasClass(this.settings.OpenClass);
                        if (!isOpen) {
                            newtab.addClass(this.settings.OpenClass);
                        }
                        newContentHeight = newtab.get(0).offsetHeight + 'px';
                        if (!isOpen) {
                            newtab.removeClass(this.settings.OpenClass);
                        }
                    }
                    tab = tab.length ? $(tab.get(0)) : tab;
                    switch (this.settings.AnimationType) {
                        case TabsAnimationType.Slide:
                            switch (type) {
                                case TabsAnimationDirection.In:
                                    tab.stop().css({
                                        height: 0,
                                    }).animate({
                                        height: contentHeight,
                                    }, this.settings.AnimationFadeTime, this.settings.AnimationFadeEase, triggerOnComplete);
                                    break;
                                case TabsAnimationDirection.Out:
                                    tab.stop().animate({
                                        height: 0,
                                    }, this.settings.AnimationSlideTime, this.settings.AnimationSlideEase, triggerOnComplete);
                                    break;
                            }
                            break;
                        case TabsAnimationType.Fade:
                            switch (type) {
                                case TabsAnimationDirection.In:
                                    tab.stop().css({
                                        opacity: 0,
                                    }).animate({
                                        opacity: 1,
                                    }, this.settings.AnimationFadeTime, this.settings.AnimationFadeEase, triggerOnComplete);
                                    break;
                                case TabsAnimationDirection.Out:
                                    tab.stop().animate({
                                        opacity: 0,
                                    }, this.settings.AnimationSlideTime, this.settings.AnimationSlideEase, triggerOnComplete);
                                    break;
                            }
                            break;
                        case TabsAnimationType.FadeAndSlide:
                            switch (type) {
                                case TabsAnimationDirection.In:
                                    tab.stop().css({
                                        height: contentHeight,
                                        opacity: 0,
                                    }).animate({
                                        height: contentHeight,
                                        opacity: 1,
                                    }, this.settings.AnimationFadeTime, this.settings.AnimationFadeEase, triggerOnComplete);
                                    break;
                                case TabsAnimationDirection.Out:
                                    const animationArgs: any = {
                                        height: newContentHeight,
                                        opacity: 0,
                                    };
                                    tab.stop().animate(animationArgs, this.settings.AnimationSlideTime, this.settings.AnimationSlideEase, triggerOnComplete);
                                    break;
                            }
                            break;
                    }
                }
            }
        }
    }

    function onControlClick(event: JQuery.Event): void {
        event.preventDefault();
        const tabs: Tabs = new Tabs(this);
        tabs.open();
    }

    function onLoadHashCheck(): void {
        if (window.location.hash && $(window.location.hash).length && $(window.location.hash).hasClass(TabsSettings.ContentClass)) {
            const tabs: Tabs = new Tabs($(window.location.hash).get(0));
            tabs.open(null, false, true);
        }
    }

    $(function() {
        $(document).on('click', '.' + TabsSettings.ControlClass, onControlClick);
        onLoadHashCheck();
    });

}
