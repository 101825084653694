namespace gotoAndPlay {

    class EventsFilter {

        private startDateInput        = '.js-filter__date--start';
        private endDateInput          = '.js-filter__date--end';
        private searchCategoriesInput = '.js-search-categories-input';
        private searchButton          = '.js-search-events-button';
        private baseUrl               = window.location.href.split('page/')[0].split('/?')[0].split('#event-')[0];
        private xhr;
        private timeout;
        private startDateValue;
        private endDateValue;
        private searchCategories;

        constructor() {
            $(document).on('click', this.searchButton, () => {
                this.search(0);
                $('.js-visible').removeClass('js-visible');
                $('.filter .button').addClass('js-loading');
            });
        }

        public search(timeout: number = 500, force: boolean = false) {
            let startDateValue: string     = String($(this.startDateInput).val());
            let endDateValue: string       = String($(this.endDateInput).val());
            let searchCategories: string[] = [];
            $(this.searchCategoriesInput).each(function() {
                if ($(this).attr('checked') == 'checked') {
                    searchCategories.push($(this).attr('data-category-slug'));
                }
            });

            if (startDateValue != this.startDateValue || endDateValue != this.endDateValue || searchCategories != this.searchCategories || force) {
                if (this.xhr) {
                    this.xhr.abort();
                }
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => {
                    this.startDateValue   = startDateValue;
                    this.endDateValue     = endDateValue;
                    this.searchCategories = searchCategories;
                    this.xhr              = $.post(ajaxPath, {
                        action: 'events-search',
                        data: {
                            start_date: this.startDateValue,
                            end_date: this.endDateValue,
                            categories: this.searchCategories,
                            base_url: this.baseUrl,
                        },
                    });
                    this.xhr.then((result) => {
                        window.history.pushState('object or string', 'Title', result['new_url']);
                        $('.js-events__list').replaceWith($('.js-events__list', result['response_html']));
                        $(document).trigger('reload');
                        $('.filter .button').removeClass('js-loading');
                    });
                }, timeout);
            }
        }

        public update() {
            this.search(0, true);
        }
    }
    let filter = new EventsFilter();

    $(document).ready(function(){
        ($('.js-events') as any).fancybox({
            arrows: false,
            infobar: false
        });

        $(document).keypress(function(e) {
            if (e.which == 13) {
                if ($('body').hasClass('page-template-calendar')) {
                    $('.js-search-events-button').trigger('click');
                } else if ($('body').hasClass('page-template-people')) {
                    $('.js-search-button').trigger('click');
                } else {
                    $('.js-search-news-button').trigger('click');
                }
            }
        });

        if ($('body').hasClass('page-template-calendar')) {
            let arr = window.location.href.split('#event-');
            if (arr.length > 1) {
                let event = arr[1];
                window.history.pushState('object or string', 'Title', arr[0]);
                if ($('a[data-src=#event-' + event + ']').length == 0) {
                    getEventModal(event);
                } else {
                    $('a[data-src=#event-' + event + ']').trigger('click');
                }
            }
        }

        function getEventModal(event) {
            console.log(event);
            $.post(ajaxPath, {
                action: 'get-event-modal',
                data: {
                    event: event,
                },
            }).then((result) => {
                let modalContent = '<div style="display: none"><a data-src="#event-' + event + '" data-fancybox="event-' + event + '" href="javascript:;" class="js-events">' + event + '</a>' + result['response_html'] + '</div>';
                $('ul.events__list').after(modalContent);
                $('a[data-src=#event-' + event + ']').trigger('click');
            });
        }
    });
}
