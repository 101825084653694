namespace gotoAndPlay {
    $(document).ready(function(){
        $('.notification').each(function(){
            var current = $(this);
            var delay = parseInt($(this).attr('data-delay')) * 1000;
            var id = $(this).attr('data-id');
            var reset = $(this).attr('data-reset');

            if(Cookie.get('reaalkool_popup') != id) {
                setTimeout(function(){
                    $(current).addClass('js-visible');
                }, delay);
            }
        });

        $('.notification__close').click(function(){
            var id = $(this).parents('.notification').attr('data-id');
            var reset = $(this).parents('.notification').attr('data-reset');
            Cookie.set('reaalkool_popup', id, reset);
        });
    });

}
