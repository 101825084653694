namespace gotoAndPlay {

    function onInit(): void {

        $('a[href*=".jpg"], a[href*=".jpeg"], a[href*=".gif"], a[href*=".png"]').fancybox({
            'loop': true,
            'buttons' : [
                //'slideShow',
                'fullScreen',
                'thumbs',
                //'share',
                'download',
                'zoom',
                'close'
            ]
        });

    }

    $(document).ready(onInit);
}
