//import('import/js/lazysizes-umd.js');
//import('import/js/jquery.serialize-object.js');
//import('import/js/jquery.fancybox.js');

namespace gotoAndPlay {

    // WordPress variables
    export let version: string;
    export let templatePath: string;
    export let ajaxPath: string;
    export let svgPath: string;
    export let cookiePath: string;
    export let cookieDomain: string;
    export let nonce: string;
    export let loggedIn: boolean;
    export let headerOffset: number;

    // Add jQuery
    export let $: JQueryStatic = jQuery;

    export class Helpers {

        static scrollTo(event: JQuery.Event): void {
            const selector = $(event.currentTarget).attr('href');
            const target   = $(selector);

            if (target.length > 0) {
                event.preventDefault();
                Helpers.scrollToTarget(target);
            }
        }

        static scrollToTarget(target: JQuery, offsetTop: number = 0): void {

            $('html, body').animate({
                scrollTop: target.offset().top - offsetTop - (headerOffset ? headerOffset : 0),
            }, 500);
        }

        static isOnScreen(target: JQuery, partial: boolean = true): boolean {

            const win           = $(window);
            const viewTop       = win.scrollTop();
            const viewBottom    = viewTop + win.height();
            const top           = target.offset().top;
            const bottom        = top + target.height();
            const compareTop    = partial === true ? bottom : top;
            const compareBottom = partial === true ? top : bottom;

            return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
        }

        static disableScroll() {
            if (!$('body').hasClass('is-scroll-disabled')) {
                const curScroll = $(window).scrollTop();

                $('body').addClass('is-scroll-disabled');
                $('body').css('top', -curScroll);
            }
        }

        static enableScroll() {
            const bodyScroll = parseInt($('body').css('top'), 10);

            $('body').removeClass('is-scroll-disabled');
            if (bodyScroll) {
                $(window).scrollTop(-bodyScroll);
                $('body').css('top', 0);
            }
        }

        static isURL(str: string) {
            const regexp = /^https?:\/\//i;

            return regexp.test(str);
        }

        static toFixed(n: number, prec: number): number {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            let k = Math.pow(10, prec);

            return Math.round(n * k) / k;
        }

        static formatNumber(num: number, decimals: number = 2, decPoint: string = '.', thousandsSep: string = ''): string {
            let n    = !isFinite(+num) ? 0 : +num;
            let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
            let sep  = thousandsSep === null ? ' ' : thousandsSep;
            let dec  = decPoint === null ? ',' : decPoint;
            let s    = (prec ? Helpers.toFixed(n, prec) : Math.round(n)).toString().split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }

            return s.join(dec);
        }

        static serializeObject(formElement: JQuery): any {
            const formArray: any = formElement.serializeObject();
            formElement.find('[type=checkbox]:not(:checked)').each(function() {
                formArray[$(this).attr('name')] = '';
            });

            return formArray;
        }

        static parseFloat(val: string): number {
            let num = parseFloat(val);
            if (typeof num == 'number' && !isNaN(num)) {
                return num;
            } else {
                return 0;
            }
        }

        static get isIE(): boolean | number {

            const ua: string   = window.navigator.userAgent;
            const msie: number = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number

                return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            const trident: number = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                const rv = ua.indexOf('rv:');

                return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }

            const edge: number = ua.indexOf('Edge/');
            if (edge > 0) {
                // Edge (IE 12+) => return version number
                return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
            }

            // other browser

            return false;
        }

        static get isMobileDevice(): boolean {

            return ((/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) ? true : false);
        }
    }

    $(function() {
        // re-init components after gravity form post render
        $(document).on('gform_post_render', (event) => {
            $(event.currentTarget).trigger('enhance');
        });

        //toggle class on trigger (for showing hidden content, etc.)
        $('.js-trigger').click(function(event) {
            event.preventDefault();
            $(this).toggleClass('js-active');				//for the trigger state
            var triggerTarget = $(this).attr('href');
            $(triggerTarget).toggleClass('js-visible');		//for the trigger target
        });

        $('html, body').on('click', function(e){
            if($(e.target).closest('.js-visible').length == 0 && $(e.target).closest('.js-trigger').length == 0) {
              $('.js-visible.js-sideclose').removeClass('js-visible');
              $('.js-active.js-sideclose').removeClass('js-active');
            }
         });

        $('ol[start]').each(function(){
            $(this).css('counter-increment', 'list '+$(this).attr('start'));
        });

    });

}
